* {
  text-align: center;
}

h2 {
  margin-top: 50px;
  font-family: "Bebas Neue", "Nunito";
  font-size: 40px;
  color: #30394d;
  padding-bottom: 10px;
}

h3 {
  font-family: "Avenir Next", "Nunito", Arial, sans-serif;
}
p {
  margin-left: 30px;
}

.about-img {
  background-image: url("../../images/About.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0, 0.2); */
  object-fit: cover;
}

.profile-pic {
  height: 200px;
  width: 200px;
  margin: 0px auto 10px auto;
}

.python-img {
  width: 25%;
  float: left;
  margin-left: 30px;
  display: flex;
  justify-content: center;
}

.react-img {
  width: 25%;
  float: right;
  margin-right: 30px;
  display: flex;
  justify-content: center;
}

.middle-container {
  margin: 100px 0;
}

.skill-row {
  width: 50%;
  margin: 100px auto 100px auto;
  text-align: left;
  line-height: 2;
}

.skills {
  margin-top: 100px;
}
