* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Avenir", "Nunito";
}

.home,
.about,
.contact,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  background-image: url("./images/About.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 100px;
}

.contact {
  background-image: url("./images/img-1.jpg");
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 100px;
}

.sign-up {
  background-image: url("./images/img-8.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ffffff;
  font-size: 100px;
}

.pathfindingvisualizer {
  & navbar {
    background: linear-gradient(-45deg, #52429a, #0d87b5);
  }
}

.an-bold {
  font-family: "AN-Bold";
}
