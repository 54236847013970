* {
  text-align: center;
}

h2 {
  margin: 50px;
  font-family: "Bebas Neue", "Oswald";
  font-size: 40px;
  color: #30394d;
  padding-bottom: 10px;
}

h3 {
  font-family: "Avenir Next", "Nunito", Arial, sans-serif;
}

.carousel {
  margin: 10px 30px 10px 30px;
}

.folder-structure {
  background-image: url("../../images/thoughtGardenFolderStructure.png");
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
  height: 100vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0, 0.2); */
  object-fit: center;
  border-radius: 15px;
  z-index: 0;
  margin: 20px auto 20px;
}

.project-video {
  background-image: url("../../images/thoughtGarden.png");
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  height: 80vh;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0, 0.2); */
  object-fit: center;
  border-radius: 15px;
  z-index: 0;
  margin: 90px auto 20px;
}

.thoughtgarden-sketch {
  background-image: url("../../images/thoughtGardenSystemDiagram.png");
  background-position: center;
  background-size: 75%;
  background-repeat: no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0, 0.2); */
  object-fit: center;
  border-radius: 15px;
  z-index: 0;
  margin: 60px auto 10px;
  transition: transform 0.25s ease;
}

/* .thoughtgarden-sketch:hover {
  -webkit-transform: scale(1.5); /* or some other value */
transform: scale(1.5);
*/ .project-description {
  margin: 10px 80px;
}

.profile-pic {
  height: 200px;
  width: 200px;
  /*margin: 100px auto 10px auto;*/
}

.python-img {
  width: 25%;
  float: left;
  margin-right: 30px;
}

.react-img {
  width: 25%;
  float: right;
  margin-left: 30px;
}

.middle-container {
  margin: 100px 0;
}

.skill-row {
  display: flex;
}

.skills {
  margin-top: 100px;
}

.see-live {
  background-color: #6749dc;
  border-radius: 5px;
  padding: 10px;
  margin-top: 30px;
  color: white;
}

.see-live:hover {
  background-color: white;
  cursor: pointer;
  color: #6749dc;
  border: 1px solid #6749dc;
  transition: all 0.3s ease-out;
}

.snippets {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.end-quote {
  margin: 10px 80px 50px 80px;
}
