.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 2.5%;
  top: 100px;
  height: 80%;
  width: 95%;
  position: absolute;
}
