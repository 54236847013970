.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.options-container2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
}

.option-button {
  margin: 5px;
  border-radius: 25px;
  padding: 8px;
  border-color: blue;
  background: transparent;
  text-align: center;
  cursor: pointer;
}

.option-button2 {
  margin: 5px 8px;
  border-radius: 25px;
  padding: 8px 20px 8px 20px;
  border-color: blue;
  background: transparent;
  text-align: center;
  cursor: pointer;
}
