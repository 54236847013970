/* video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
} */

/* .macnlaptop {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
} */
.chatbot-button {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  border: none;
  z-index: 9;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  background-image: url("../../images/purpleChatBubble.png");
  background-size: 70%;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 50px;
  right: 50px;
}

.tag1 {
  position: absolute;
  bottom: 250px;
  left: 100px;
  font-family: "Avenir Next", Arial, "Nunito", sans-serif;
  font-size: 70px;
  margin: 25px auto 25px auto;
  color: #f4f4f4;
  text-shadow: 2px 2px 2px #123756;
}

.tag2 {
  color: #f4f4f4;
  font-family: "Monaco", monospace;
  font-size: 30px;
  font-weight: normal;
  position: absolute;
  bottom: 210px;
  left: 100px;
  margin: 50px 0px 0px 20px;
  /* text-shadow: 2px 2px 2px #123756; */
}

.hero-container {
  background: linear-gradient(-45deg, #0d87b5, #52429a);
  /* background-image: url("../images/mac-and-laptop.png"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0, 0.2); */
  object-fit: cover;
}

.hero-img {
  background-image: url("../../images/mac-and-laptop.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 0 1000px rgba(0,0,0, 0.2); */
  object-fit: cover;
}

.hero-container > h1 {
  color: #ffffff;
  font-size: 80px;
  margin-top: -100px;
  font-family: "Avenir Next";
}

.hero-container > p {
  color: #ffffff;
  font-size: 32px;
  margin-bottom: 10px;
  font-family: "Avenir Next", "Nunito";
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
    font-family: "Avenir Next", "Nunito";
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
    font-family: "Avenir Next", "Nunito";
  }

  .hero-container > p {
    font-size: 30px;
    margin-top: -10px;
    font-family: "Avenir Next", "Nunito";
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .main-chatbot {
    display: none;
  }
}
