.infocard-container {
  padding: 15px;
  border-radius: 4px;
  background: linear-gradient(-45deg, #52429a, #0d87b5);
  font-size: 15px;
  border-color: blue;
  border-radius: 4px;
  border: 1px solid #0d87b5;
  color: white;
}

.infocard-container2 {
  padding: 15px;
  border-radius: 4px;
  background: FFFFFF;
  font-size: 15px;
  border-color: blue;
  border-radius: 4px;
  border: 1px solid #0d87b5;
  color: #52429a;
}

/* .infocard-button {
  width: 40px;
  height: 40px;
  background-image: url("../../images/nextButton.png");
  background-size: 100%;
  margin: 10px 10px 10px 155px;
  cursor: pointer;
} */

.infocard-button {
  width: 75px;
  height: 40px;
  margin: 10px 10px 10px 125px;
  cursor: pointer;
  border-radius: 40px;
  border: 2px solid #0d87b5;
  z-index: 1;
  position: relative;
  color: #0d87b5;
  transition: all 0.2s;
  overflow: hidden;
}
.infocard-button:hover {
  color: white;
}

.infocard-button::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0%;
  height: 100%;
  z-index: -1;
  border-radius: 40px;
  background: linear-gradient(90deg, #52429a, #0d87b5);
  transition: all 0.2s;
  color: #fff;
}

.infocard-button::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  border-radius: 40px;
  background: transparent;
}

.infocard-button:hover::before,
.infocard-button:focus::before {
  width: 100%;
}

.fa.fa-chevron-right {
  margin: 10px;
  z-index: 3;
}

/* linear-gradient(-45deg, #0d87b5, #52429a); */
