.carousel {
  position: relative;
  height: 80vh;
  justify-content: center;
  align-items: center;
}

.image {
  width: 50%;
  height: 50%;
  border-radius: 10px;
  margin-top: 0px;
  margin-bottom: 50px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  color: #6749dc;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  color: #6749dc;
  z-index: 2;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide p {
  display: none;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.05);
}

.slide.active p {
  display: block;
}

.snippet-description {
  margin: 20px 80px;
}
